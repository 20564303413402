<template>
    <validation-provider v-slot="{ errors }" tag="div" :name="$t('question')" :rules="question.rules">
        <b-row>
            <b-col cols="12" class="mx-auto">
                <h1 class="font-weight-bolder mb-3 text-center">{{ question.title }}</h1>
                <h4 class="mb-2 d-flex justify-content-center" v-html="question.description"></h4>
            </b-col>
            <b-col cols="12" class="mx-auto text-center">
                <div v-if="question.type === 'text'" class="w-50 mx-auto">
                    <b-input-group :prepend="question.suffix" :append="question.prefix">
                        <b-input v-model="innerValue" type="text" :placeholder="question.placeholder" />
                    </b-input-group>
                </div>
                <div v-else-if="question.type === 'textArea'" class="w-50 mx-auto">
                    <b-textarea v-model="innerValue" rows="4" :placeholder="question.placeholder" />
                </div>
                <div v-else-if="question.type === 'radio'">
                    <b-radio-group v-model="innerValue" class="d-flex justify-content-evenly flex-wrap">
                        <template v-for="(answer, index) in question.answers">
                            <b-radio :key="index" :value="answer.value" >
                                <div class="card card_box p-2 border">
                                    <img :src="answer.thumbnail" alt="" />
                                    <div class="card-body px-0">
                                        <h4 class="card-title">{{ answer.label }}</h4>
                                        <p class="card-text">{{ answer.description }}</p>
                                    </div>
                                </div>
                            </b-radio>
                        </template>
                    </b-radio-group>
                </div>
                <div v-else-if="question.type === 'checkbox'">
                    <b-checkbox-group v-model="innerValue" class="d-flex justify-content-evenly flex-wrap">
                        <template v-for="(answer, index) in question.answers">
                            <b-checkbox :key="index" :value="answer.value">
                                <div class="card card_box p-2 border">
                                    <img :src="answer.thumbnail" alt="" />
                                    <div class="card-body px-0">
                                        <h4 class="card-title">{{ answer.label }}</h4>
                                        <p class="card-text">{{ answer.description }}</p>
                                    </div>
                                </div>
                            </b-checkbox>
                        </template>
                    </b-checkbox-group>
                </div>
                <div v-else-if="question.type === 'select'" class="w-50 mx-auto">
                    <v-select
                        v-model="innerValue"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :placeholder="question.placeholder"
                        :options="question.answers"
                        :reduce="(answer) => answer.value"
                    />
                </div>
                <div v-if="errors.length" class="text-danger text-right">{{ errors[0] }}</div>
            </b-col>
        </b-row>
    </validation-provider>
</template>

<script>
import vSelect from 'vue-select'

export default {
    name: 'Question',
    components: {
        vSelect,
    },
    props: {
        value: {
            type: null,
            default: null,
        },
        question: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            innerValue: null,
        }
    },
    watch: {
        value(value) {
            this.innerValue = value
        },
        innerValue(value) {
            this.$emit('input', value)
        },
    },
    created() {
        if (this.value) {
            this.innerValue = this.value
        }
    },
}
</script>

<style lang="scss" scoped>
.justify-content-evenly {
    justify-content: space-evenly;
}
.dark-layout .card_box {
    background-color: #161d31;
}
.card_box {
    min-width: 320px;
    max-width: 320px;
}
</style>
